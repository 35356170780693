import Pub from '@/utils/public'
import { uploadCustom as uploadCustomAl } from './alioss'
import { uploadCustom as uploadCustomHs } from './huoshan'

// 上传七牛针对 Upload 组件再次封装，方便重新上传操作
export function uploadCustom(fileJson, uploadResult, bucket, source, uploadType = Pub.uploadType) {
  if (uploadType === 'al') {
    // 阿里
    uploadCustomAl(fileJson, uploadResult, bucket, source)
  } else if (uploadType === 'hs') {
    // 火山
    uploadCustomHs(fileJson, uploadResult, bucket, source)
  }
}
import TTUploader from 'tt-uploader';
import Pub from '@/utils/public'
import { getUploadHuoShanToken } from '@/api/request'

// 私有
const OSS_CDN_PRI = Pub.IS_DEBUG() ? process.env.VUE_APP_OSS_PRI_TEST_HS : process.env.VUE_APP_OSS_PRI_HS
// 公有
const OSS_CDN_PUB = Pub.IS_DEBUG() ? process.env.VUE_APP_OSS_PUB_TEST_HS : process.env.VUE_APP_OSS_PUB_HS

// ================================= 《 针对 Upload 组件封装，无用可删除 》

// 上传七牛针对 Upload 组件再次封装，方便重新上传操作
export function uploadCustom(fileJson, uploadResult, bucket, source) {
  // 检查是否有文件
  if (fileJson.file) {
    // 设置为上传状态
    fileJson.status = 'uploading'
    // 清空上传进度
    fileJson.percent = 0
    // 初始回调
    if (uploadResult) { uploadResult(fileJson) }
    // 开始上传
    uploadPro({
      file: fileJson.file,
      bucket: bucket,
      source: source,
      success: (res) => {
        // 输出结果
        // console.log('上传成功', res)
        // 设置为成功状态
        fileJson.status = 'done'
        // 上传成功则清空 file 对象，因为只有失败才可能需要重新上传
        fileJson.file = undefined
        // 配置 key
        fileJson.name = res.name
        fileJson.key = res.key
        fileJson.url = res.url
        fileJson.duration = res.duration
        fileJson.size = res.size
        // 回调结果
        if (uploadResult) { uploadResult(fileJson, res) }
      },
      fail: (err) => {
        // 输出错误
        // console.log('上传失败', err)
        // 设置为失败状态
        fileJson.status = 'error'
        // 回调结果
        if (uploadResult) { uploadResult(fileJson, err) }
      },
      progress: (p, cpt, res) => {
        // 输出进度
        // console.log('上传进度', p, cpt, res)
        // 记录进度
        fileJson.percent = p
        // 回调结果
        if (uploadResult) { uploadResult(fileJson) }
      }
    })
  }
}

// ================================= 《 针对项目封装 》

/** 上传文件到阿里云加强版
 * @param  {} file 文件
 * @param  {} bucket 顶部有
 * @param  {} source 调用位置，用于记录
 * @param  {} success 成功回调
 * @param  {} fail 失败回调
 * @param  {} progress 进度回调
 */
export function uploadPro({
  file,
  bucket,
  source = 'all',
  success,
  fail,
  progress
}) {
  if (Pub.FILE_IS_VIDEO(file.name)) {
    // 视频文件
    const url = URL.createObjectURL(file)
    const video = document.createElement('video')
    video.onloadedmetadata = () => {
      URL.revokeObjectURL(url)
      upload({ file, bucket, source, width: video.videoWidth, height: video.videoHeight, duration: video.duration, size: file.size, success, fail, progress })
    }
    video.src = url
    video.load()
  } else {
    // 非视频文件
    upload({ file, bucket, source, success, fail, progress })
  }
}

/** 上传文件到阿里云
 * @param  {} file 文件
 * @param  {} bucket 顶部有
 * @param  {} source 调用位置，用于记录
 * @param  {} width 视频宽度
 * @param  {} height 视频高度
 * @param  {} duration 视频时长
 * @param  {} size 文件大小
 * @param  {} success 成功回调
 * @param  {} fail 失败回调
 * @param  {} progress 进度回调
 */
export function upload({
  file,
  bucket,
  source = 'all',
  width = 0,
  height = 0,
  duration = 0,
  size = 0,
  success,
  fail,
  progress
}) {
  // 获取文件后缀
  const extend = file.name.substr(file.name.lastIndexOf('.') + 1)
  // 服务器获取 token 参数
  const params = {
    bucket: bucket,
    extend: extend,
    name: file.name,
    source: source,
    width: width || 0,
    height: height || 0
  }
  // 从上传配置信息
  getUploadHuoShanToken(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      const uploader = new TTUploader({
        // 建议设置能识别用户的唯一标识 ID，用于上传出错时排查问题，不要传入非 ASCII 编码
        userId: data.user_id,
        // 在视频点播-应用服务中创建的 AppID，视频点播的质量监控等都是以这个参数来区分业务方的，务必正确填写
        appId: data.app_id,
        // 仅视频/普通文件上传时需要配置
        videoConfig: {
          // 在视频点播中申请的点播空间名
          spaceName: data.bucket
        }
      })
      const fileKey = uploader.addFile({
        file: file,
        // 文件路径
        fileName: data.key,
        // 从服务端拿到的 token，见下方说明
        stsToken: data.token
        // 上传文件类型，四个可选值：video(视频或者音频，默认值)，image(图片)，object（普通文件，例如字幕），media（素材文件）
        // type: 'video'
      })
      // 上传完成
      uploader.on('complete', (res) => {
        // 成功
        if (bucket === Pub.BUCKET_PRI()) {
          res.url = OSS_CDN_PRI + data.key
          res.cover = OSS_CDN_PRI + res.uploadResult.PosterUri.split('/')[1]
        } else if (bucket === Pub.BUCKET_PUB()) {
          res.url = OSS_CDN_PUB + data.key
          res.cover = OSS_CDN_PRI + res.uploadResult.PosterUri.split('/')[1]
        }
        // 依然就文件名
        res.name = file.name
        // 记录
        res.key = data.key
        res.duration = duration
        res.size = size
        if (success) { success(res) }
      })
      // 上传失败
      uploader.on('error', (err) => {
        if (fail) { fail(err) }
      })
      // 上传中
      // uploader.on('progress', (res) => {
      //   console.log('progress', res.percent)
      // })
      // 开始上传
      uploader.start(fileKey)
    } else {
      // 失败
      if (fail) { fail(new Error('获取Token失败!')) }
    }
  }).catch(err => {
    // 失败
    if (fail) { fail(err) }
  })
}
